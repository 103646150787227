/*******************last module congratulation style*************************/
.main-container .sub-container {
    width: 50%;
    max-width: 500px;
    margin: auto;
    /* height: 630px; */
    /* margin-left: 0px; */
}

.main-container .sub-container .image-container {
    /* display: flex; */
    /* max-width: 630px; */
    /* max-height: 630px; */
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
    padding: 20px;
    text-align: center;
}

.main-container .sub-container .image-container img {
    width: 100%;
}

@media(max-width:992px) {
    .main-container .sub-container {
        width: 1000%;
        max-width: unset;
        /* margin-bottom: 50px */
    }

    .main-container .sub-container .image-container {
        margin-bottom: 50px;
    }
}