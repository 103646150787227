.part7-third-main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  /* justify-content: space-between; */
}

.part7-third-main-container .image-third-container {
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: #FFF;
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
  text-align: center;
  padding: 20px;
  width: 32.4%;
  margin: 0px;
  display: unset;
  margin-top: 0px !important;
  object-fit: contain;
}

.part7-third-main-container .image-third-container img {
  width: 100%;
  max-height: 362px;
  max-width: 362px;
}

.part7-third-main-container,
.image-third-container p {
  margin-top: 20px;
}

.roam-and-zone {
  position: relative;
  /* border: 1px solid red; */
}

.roam-and-zone:hover {
  cursor: pointer;
}

.hover-roam-and-zone {
  /* bottom: 450px;
  max-width: 300px;
  background-color: var(--mahroon);
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 9999999999 !important;
  right: 0px; */
  color: '#000';
  font-size: 14px;
  font-family: var(--font_comicRegular);
}

/* media queries*********************/

@media (max-width: 1400px) {
  .part7-third-main-container {
    /* flex-wrap: nowrap; */
    /* flex-shrink: 2; */
    justify-content: space-between;
  }

  .part7-third-main-container .image-third-container {
    width: 48.5%;
  }
}

@media (max-width: 992px) {
  .part7-third-main-container .image-third-container {
    width: 100%;
  }
}