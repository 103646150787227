.badgeContainer {
    width: 100%;
    max-width: 1532px;
    min-width: 100px;
    /* border: 1px solid red; */
    margin: auto;
    /* margin-top: 70px; */
    border-radius: 35px;
    background: #F2ECFA;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.25);
    padding: 55px;
    justify-content: center;
}

.badgeContainer .visualContainer {
    width: 100%;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}

.badgeContainer .visualContainer .logo {
    width: 30%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 302px !important;
    /* height: 302px; */
    max-height: 302px;
    border-radius: 30px;
    border: 2px solid #3F0866;
    background: #FFF;
    box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.25);
}

.badgeContainer .visualContainer .logo img {
    width: 100%;
    /* width: 302px; */
    height: 100%;
    border-radius: 30px;
}

.badgeContainer .visualContainer .trophy {
    width: 42%;
    display: flex;
    justify-content: center;
    max-width: 610px;
    overflow: hidden;
    max-height: 302px;
    border-radius: 30px !important;
    background-color: #fff;
}

.badgeContainer .visualContainer .trophy img {
    /* width: 100%;
    max-width: 610px;
    height: 302px; */
    max-height: 302px;
    border-radius: 30px;
    width: 100%;
    max-width: 610px;
    max-height: 302px;
    transform: scale(1.5);
    border-radius: 30px;
    overflow: hidden;
    /* margin-top: 64px; */
    /* z-index: 1; */
    object-fit: contain;
}

.badgeContainer .visualContainer .text {
    width: 30%;
}

.greetingMessage1 {
    color: #6A3851;
    font-size: 32px !important;
    margin: 0px !important;
    font-weight: 400;
    line-height: 60.5px !important;
    font-style: normal;
    font-family: var(--font_banger);
}

.greetingMessage2 {
    color: #FECB00;
    font-size: 43px !important;
    margin: 0px !important;
    font-weight: 400;
    line-height: 60.5px !important;
    font-style: normal;
    font-family: var(--font_banger);
}

.greetingMessage3 {
    color: #FECB00;
    font-size: 32px;
    margin: 0px !important;
    font-weight: 400;
    line-height: 60.5px !important;
    font-style: normal;
    font-family: var(--font_banger);
}

.badgeContainer .progressBar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
}

.badgeContainer .progressBarMarginTop {
    margin-top: 102px;
}

.badgeContainer .progressBar p {
    margin-top: 20px;
    color: #323232;
    font-family: var(--font_comicRegular);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.badgeContainer .progressBar p>span {
    font-weight: 700;
}

.badgeContainer .progressBar .logo {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}

.badgeContainer .progressBar .logo p {
    text-align: center;
}

.badgeContainer .progressBar img {
    /* width: 156px;
    height: 156px; */
    width: 100%;
    max-width: 156px;
    /* max-height: ; */
    border-radius: 30px;
    border: 2px solid #3F0866;
    background: #fff;

}

.congratulation-box {
    width: 100%;
    max-width: 1532px;
    margin-top: 70px;
}



/********************* media Queries***************** */
@media(max-width:1791px) {

    .badgeContainer .visualContainer {
        gap: 50px;
        justify-content: center;
    }

    .visualContainer .logo {
        width: 50% !important;
    }

    .visualContainer .trophy {
        width: 50% !important;
    }

    .visualContainer .text {
        margin-top: 30px;
        width: 100% !important;
    }
}


@media(max-width:1759px) {

    /* .visualContainer .logo {
        width: 50% !important;
    }

    .visualContainer .trophy {
        width: 50% !important;
    } */

    /* .visualContainer .text {
        margin-top: 30px;
        width: 100% !important;
    } */
}

@media(max-width:1400px) {
    .badgeContainer .visualContainer .trophy img {
        transform: scale(1);
    }
}

@media(max-width:1164px) {
    .visualContainer .trophy {
        width: 100% !important;
    }

    .badgeContainer .visualContainer .trophy img {
        transform: scale(1.5);
    }
}

@media(max-width:1150px) {
    .badgeContainer {
        padding: 20px;
    }

    .badgeContainer .visualContainer {
        gap: 50px;
        justify-content: center;
    }

    .visualContainer .logo {
        width: 100% !important;
    }

    .visualContainer .trophy {
        width: 100% !important;
    }

    .visualContainer .text {
        /* margin-top: 30px; */
        width: 100% !important;
        margin-top: 0px !important;
    }
}

@media(max-width:868px) {
    .badgeContainer .visualContainer .trophy img {
        transform: scale(1.3);
    }
}

@media(max-width:567px) {
    /* .badgeContainer .progressBar .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    } */

    .badgeContainer .visualContainer .trophy img {
        transform: scale(1);
    }
}