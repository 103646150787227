.sliderFromSide {
  animation-name: forwardsSuperhero;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.sliderFromSide-position {
  /* height: 329px; */
  height: 353px;
  width: 679px;
  background-color: var(--purple);
  border-radius: 20px 0px 0px 0px;
  box-shadow: 0px -5px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 113px;
  right: -700px;
}


@keyframes forwardsSuperhero {
  100% {
    right: 0px;
  }
}

.sliderFromSide-hide {
  height: 329px;
  width: 679px;
  background-color: var(--purple);
  border-radius: 20px 0px 0px 0px;
  box-shadow: 0px -5px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 107px;
  right: 0px;
  animation-name: forwardsSuperhero-hidden;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes forwardsSuperhero-hidden {
  100% {
    right: -700px;
  }
}

.sliderFromSide .section {
  display: flex;
  height: 100%;
  width: 100%;
}

.sliderFromSide .section .firstDiv {
  width: 279px;
  height: 279px;
  margin-right: 23px;
  /* width: 0%; */
  /* height: 90%; */
  /* margin: 20px; */
}

.sliderFromSide .section .firstDiv img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.sliderFromSide .section .secondDiv {
  width: 60%;
}

.sliderFromSide .secondDiv .seprator {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.sliderFromSide .secondDiv .seprator .heading {
  color: #fff;
  font-family: var(--font_banger);
  font-size: 35px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0px;
}

.sliderFromSide .secondDiv .seprator p:nth-child(2) {
  color: #fff;
  font-size: 35px !important;
  margin-bottom: 0px;
  cursor: pointer;
}

.sliderFromSide .secondDiv .details {
  color: #fff;
  font-family: var(--font_comicRegular);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px !important;
}

.sliderFromSide .secondDiv .callOutButton {
  display: inline-flex;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fecb00;
  margin-bottom: 15px;
  cursor: pointer;
}

.comicBook p,
.sliderFromSide .secondDiv .callOutButton p {
  color: var(--purple);
  text-align: center;
  font-family: var(--font_comicRegular);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 21px !important;
  margin: 0;
}

.comicBook {
  display: inline-flex;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
  margin: 0;
  display: flex;
  cursor: pointer;
}

.comicBook p {}

/**************************** foooter  motivator slider *******************/
.topcallout,
.comic,
.says {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 112px;
  right: -450px;
  max-width: 433px;
  /* max-height: 452px; */
  border-top-left-radius: 20px;
  background: #fff;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  animation-name: superhero-motivators;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.comic-position {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 107px;
  right: -450px;
  max-width: 433px;
  border-top-left-radius: 20px;
  background: #fff;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.comic {
  z-index: 20;
  bottom: 460px;
}

.topcallout {
  height: 470px;
  max-width: 433px;
  top: 56px;
  bottom: unset;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.1);
}

@keyframes superhero-motivators {
  100% {
    right: 0px;
  }
}

.topcallout-hidden,
.comic-hidden,
.says-hidden {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 107px;
  right: 0px;
  max-width: 433px;
  /* max-height: 452px; */
  border-top-left-radius: 20px 0px 0px 0px;
  background: #fff;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  animation-name: superhero-motivators-hidden;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.topcallout-hidden {
  display: none;
}

.comic-hidden {
  z-index: 20px;
  bottom: 437px;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes superhero-motivators-hidden {
  100% {
    right: -450px;
    display: none;
  }
}

.topcallout .imageDiv,
.comic .imageDiv,
.says .imageDiv {
  /* height: 90%; */
  width: 100%;
  border: 2px solid rgb(172, 169, 169);
  border-radius: 15px;
}

.topcallout .imageDiv img,
.comic .imageDiv img,
.says .imageDiv img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* aspect-ratio: 1;
     */
  /* transform: scaleX(1.5) scaleY(1.3);
    padding-right: 50px; */
}

.says .imageDiv img {
  width: 369px;
  height: 369px;
}

.topcallout h1,
.comic h1,
.says h1 {
  color: var(--mahroon);
  font-family: var(--font_banger);
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.topcallout .topBanner,
.comic .topBanner,
.says .topBanner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.topcallout .topBanner img:hover,
.comic .topBanner img:hover,
.says .topBanner img:hover {
  cursor: pointer;
}

/*****************motivator slider end**************************/

/* footer */

.footer-superhero-feeling {
  display: flex;
  justify-content: right;
  align-items: center;
}

.footerSuperHero {
  display: flex;
  width: 50%;
  height: 92px;
  justify-content: right;
  align-items: center;
  flex-shrink: 0;
  margin-right: 45px;
}

.footerSuperHero img {
  justify-content: left;
  width: 92px;
  height: 92px;
  border-radius: 13px;
  border: 1px solid var(--light_grey);
  background: var(--white);
}

.footerSuperHero img:hover {
  border: 3px solid var(--purple);
  cursor: pointer;
}

.sadLife {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  max-width: 180px;
  gap: 10px;
  border-radius: 10px;
  background: var(--mahroon);
  border: 1px solid var(--mahroon);
}

.sadLife:hover {
  background-color: var(--white);
  border: 1px solid var(--purple);
  cursor: pointer;
}

.sadLife>a {
  font-size: 13px;
  text-align: center;
  border-radius: 3px;
  display: block;
}

.sadLife span {
  color: var(--yellow);
  text-align: center;
  font-family: var(--font_comicRegular);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  vertical-align: middle;
}

.sadLife:hover span {
  color: var(--purple);
}

.radio-options span {
  border: none !important;
  background-color: unset !important;
}

.footer-play-button {
  background-color: #fff;
  border: 1px solid var(--purple);
  border-radius: 50%;
  height: 38px;
  width: 38px;
}

.footer-play-button:hover {
  cursor: pointer;
}

.footer .audio-part {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
}

.audio-player-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.audio-player-container .radio-options .input-group-text input[type=radio] {
  cursor: pointer;
}

/* .audio-player-container {} */

input[type=radio],
input[type=checkbox] {
  accent-color: var(--mahroon);
}

/**media queries***/

@media (max-width: 1660px) {
  .dashboard-footer {
    padding: 0px 14px;
  }

  /* .footer-superhero-feeling {
    flex-direction: column;
    justify-content: right;
  
    align-content: flex-end;
  } */

  .footerSuperHero {
    justify-content: center;
    margin-right: 0px !important;
    margin-bottom: 5px !important;
    margin-right: 10px;
  }

  .sadLife {
    max-width: 50% !important;
    margin-left: 20px;
    /* width: 100%;
    padding: 10px; */
  }

  .sliderFromSide,
  .says {
    /* bottom: 164px; */
  }

  .topcallout,
  .says {
    max-width: 333px;
    height: 366.16px;
    /* max-height: 352px; */
  }

  .comic h1,
  .topcallout h1,
  .says h1 {
    font-size: 30px;
  }

  .comic {
    bottom: 517px;
  }

  .says .imageDiv img {
    width: 269px;
    height: 269px;
  }
}

@media (max-width: 1400px) {
  .footerSuperHero img {
    height: 62px;
    width: 62px;
  }

  .sadLife {
    padding: 10px 10px;
    max-width: 160px;
  }

  .sadLife span {
    font-size: 15px !important;
  }

  .sliderFromSide,
  .says {
    /* bottom: 124px; */
  }

  .comic {
    bottom: 476px;
  }
}

@media(max-width:1200px) {
  .footer .audio-part {
    padding-left: 60px;
  }
}

@media (max-height: 1100px) {

  .comic,
  .says {
    /* max-width: 350px; */
  }

  .comic {
    bottom: 112px !important;
  }

  .sadLife img {
    height: 14px;
    width: 14px;
  }

  .sadLife span {
    font-size: 14px !important;
    line-height: 10px;
  }
}

/* @media (max-height: 1100px) and (max-width: 1660px) {
  .comic {
    bottom: 163px !important;
  }
} */

/* @media (max-height: 1100px) and (max-width: 1400px) {
  .comic {
    bottom: 123px !important;
  }
} */

@media (max-height: 1100px) and (max-width: 1006px) {
  /* .comic {
    bottom: 186px !important;
  } */
}

/* @media (max-height: 1100px) and (max-width: 820px) {
  .comic {
    bottom: 123px !important;
  }
} */

@media (max-height: 1100px) and (max-width: 767px) {
  .comic {
    bottom: 157px !important;
  }
}

@media (max-width: 1006px) {
  /* .sliderFromSide,
  .says {
    bottom: 187px;
  } */

  /* .comic {
    bottom: 516px;
  } */
}

@media (max-width: 992px) {
  .audio-player {
    gap: 15px;
  }

  .footer-play-button {
    height: 62px;
    width: 62px;
  }

  .footer-play-button img {
    height: 61px;
    width: 60px;
  }

  .audio-player-container .radio-options {
    padding-right: 12px !important;
    margin-bottom: 20px;
  }


}

@media (max-width: 950px) {
  .sliderFromSide-position {
    /* flex-direction: column; */
    max-width: 95%;
    /* height: 700px; */
    height: 370px;
  }

  .sliderFromSide .section {
    /* flex-direction: column; */
  }

  .sliderFromSide .section .firstDiv {
    width: 40%;
    /* height: 100%; */
  }

  .sliderFromSide .secondDiv .seprator p:nth-child(2),
  .sliderFromSide .secondDiv .seprator .heading {
    font-size: 30px !important;
  }

  .sliderFromSide .section .firstDiv img {
    height: unset;
  }

  .sliderFromSide .section .secondDi {
    width: 60%;
  }

  .comic {
    bottom: 494px;
  }

  .sadLife>a>img {
    height: 14px;
    width: 14px;
  }

  .sadLife span {
    font-size: 14px !important;
  }
}

@media (max-width: 820px) {
  /* .sliderFromSide,
  .says {
    bottom: 165px;
  } */

  /* .comic {
    bottom: 535px;
  } */
}

@media (max-width: 778px) {
  /* .sliderFromSide,
  .says {
    bottom: 221px;
  } */
}

@media (min-width: 768px) and (max-width: 1200px) {
  .footer .subtext-blocksec {
    flex-direction: column;
    align-items: center;
  }

  .footer .subtext-blocksec .col-md-6 {
    max-width: 100% !important;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .footer .audio-part {
    padding-left: 0px;
    justify-content: left;
    padding-right: 0px;
    align-self: flex-end;
  }

  .audio-player-container .radio-options {
    padding-right: 12px !important;
    margin-bottom: 10px;
  }

  .footer .col-md-3.col-sm-6 {
    display: flex;
    flex-direction: row !important;
    padding: 0px;
    align-self: flex-end;
  }

  .footer-superhero-feeling {
    flex-direction: row !important;
    width: 100%;
    /* justify-content: space-between; */
    margin: 0px;
    padding-bottom: 0px !important;
    align-items: flex-end;
    padding-top: 20px !important;
  }

  .footerSuperHero {
    display: unset;
    width: unset;
    /* justify-content: left; */
    justify-content: unset;
    align-items: unset;
    margin-right: 0px !important;
    padding-left: 0px;
    margin-bottom: 0px !important;
    text-align: right;
    padding-right: 15px;
    /* margin-bottom: 5px !important; */
  }

  .footerSuperHero img {
    height: 40px;
    width: 40px;
  }

  .dashboard-footer {
    padding: 0px 16px;
    height: 159px;
  }

  .row.footer .ft-headstext {
    padding-top: 10px;
  }

  .sliderFromSide,
  .says {
    bottom: 158px;
  }

  .comic {
    bottom: 526px;
  }

  .footer .subtext-blocksec {
    flex-direction: row !important;
    align-items: unset !important;
    padding-bottom: 8px;
  }

  .footer .subtext-blocksec .col-md-6 {
    max-width: 50% !important;
    /* padding-left: 0px; */
  }

  .sadLife {
    padding: 10px;
    max-width: 130px;
    height: 40px;
  }

  .sadLife>a>img {
    height: 13px;
    width: 13px;
  }

  .sadLife span {
    font-size: 13px !important;
    margin-left: 3px;
  }

  .comicBook,
  .sliderFromSide .secondDiv .callOutButton {
    padding: 15px 20px;
  }

  .comicBook p,
  .sliderFromSide .secondDiv .callOutButton p {
    font-size: 16px !important;
  }

}

@media (max-width: 555px) {
  .comic-position {
    max-width: 80%;
  }
}

@media (max-width: 506px) {
  .sliderFromSide-position {
    height: auto;
  }

  .sliderFromSide .section {
    height: auto;
    align-items: center;
    /* margin-top: 30px; */
  }

  .sliderFromSide .section {
    flex-direction: column;
  }

  .sliderFromSide .section .secondDiv,
  .sliderFromSide .section .firstDiv {
    width: 100%;
    height: auto;
    margin: 0px;
  }

  .sliderFromSide .section .firstDiv {
    margin-bottom: 16px;
    /* width: 100%; */
    width: 50%;
  }

  .sliderFromSide .secondDiv .seprator {
    margin-bottom: 5px;
  }

  .sliderFromSide .secondDiv .seprator .heading {
    font-size: 25px !important;
  }

  .sliderFromSide-position {
    padding: 15px;
  }

  .sliderFromSide .secondDiv .callOutButton,
  .sliderFromSide .secondDiv .details {
    margin-bottom: 13px;
  }


  .sliderFromSide .secondDiv .seprator p:nth-child(2) {
    position: absolute;
    top: 15px;
    right: 17px;
    color: var(--white);
    font-weight: bold;
  }

  .comic {
    bottom: 156px;
  }
}

@media (max-width: 419px) {
  .dashboard-footer {
    padding: 0px 16px;
  }

  .sadLife {
    width: 50%;
  }

  .sadLife span {
    font-size: 11px !important;
    line-height: 9px;
  }

  .sadLife>a>img {
    height: 11px;
    width: 11px;
  }

  .audio-player {
    gap: 7.5px;
  }

  .footer .audio-part {
    gap: 7.5px;
  }

  .footerSuperHero {
    padding-right: 7.5px;
  }

}