.main-container .image-third-container {

    display: flex;
    flex-direction: column;
    /* max-width: 362px;
        max-height: 362px; */
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
    text-align: center;
    padding: 20px;
    width: 32%;
    margin: 0px;

}

.main-container .image-third-container img {
    width: 100%;
}

/********************* media queries*****************************/
@media(max-width:1092px) {
    .main-container .image-third-container {
        width: 100%;
        margin-bottom: 20px;
    }
}