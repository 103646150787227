.tip-container {
    width: 100%;
    margin: 80px 0px 40px 0px;
    position: relative;
}

.tip-container .tip-image-container img {
    width: -webkit-fill-available !important;
    height: auto;
    object-fit: cover;
    max-width: 923px;
    width: 100%;
}

.tip-container .idea {
    position: absolute;
    top: -50px;
}

.tip-container .idea img {
    max-width: 219px;
    height: auto;
}

.tip-content {
    position: absolute;
    top: 155px;
    left: 135px;
    max-width: 666px;
}

.whiteText {
    color: #FFF;
    font-family: var(--font_banger);
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
}

.yellowText {
    color: #FECB00;
    font-family: var(--font_banger);
    font-size: 65px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
}

@media(max-width:1400px) and (min-width:1200px) {
    .zone-home-technique .main-container .sub-container {
        width: 400px;
    }
}

@media(max-width:1242px) {
    .tip-content {
        left: 145px;
        max-width: 601px !important;
    }

    .whiteText {
        font-size: 45px;
        line-height: 45px;
    }

    .yellowText {
        font-size: 60px;

    }
}

@media(max-width:1208px) {
    .tip-container .idea img {
        max-width: 180px;
        height: auto;
    }

    .tip-content {
        left: 115px;
        top: 125px;
        max-width: 566px !important;
    }
}

@media(max-width:1100px) {
    .tip-content {
        left: 115px;
        top: 125px;
        max-width: 500px !important;
    }

    .whiteText {
        font-size: 35px;
        line-height: 40px;
    }

    .yellowText {
        font-size: 45px;
        line-height: 55px;

    }
}

@media(max-width:1024px) {
    .tip-container .idea img {
        max-width: 160px;
        height: auto;
    }

    .tip-content {
        left: 102px;
        top: 100px;
        max-width: 500px !important;
    }
}

@media(max-width:980px) {

    .tip-content {
        left: 100px;
        top: 100px;
        max-width: 420px !important;
    }

    .whiteText {
        font-size: 30px;
        line-height: 40px;
    }

    .yellowText {
        font-size: 40px;
        line-height: 50px;

    }
}

@media(max-width:900px) {
    .tip-container .idea img {
        max-width: 150px;
        height: auto;
    }

    .tip-content {
        left: 100px;
        top: 100px;
        max-width: 350px !important;
    }

    .whiteText {
        font-size: 25px;
        line-height: 25px;
    }

    .yellowText {
        font-size: 35px;
        line-height: 35px;

    }
}

@media(max-width:840px) {
    .tip-content {
        left: 100px;
        top: 95px;
        max-width: 305px !important;
    }

    .whiteText {
        font-size: 25px;
        line-height: 25px;
    }

    .yellowText {
        font-size: 32px;
        line-height: 32px;

    }
}

@media(max-width:820px) {
    .tip-container .idea {
        top: -32px;
    }

    .tip-container .idea img {
        max-width: 160px;
    }

    .tip-content {
        top: 115px;
        left: 110px;
        max-width: 70% !important;
    }

    .whiteText {
        font-size: 35px;
        line-height: 35px;
    }

    .yellowText {
        font-size: 45px;
        line-height: 50px;
    }
}

@media(max-width:767px) {
    .tip-content {
        left: 115px;
        top: 110px;
    }

    .whiteText {
        font-size: 35px;
        line-height: 35px;
    }

    .yellowText {
        font-size: 45px;
        line-height: 45px;

    }
}

@media(max-width:700px) {

    .tip-container .idea {
        top: -55px;
    }

    .tip-content {
        left: 110px;
        top: 95px;
    }

    .whiteText {
        font-size: 30px;
        line-height: 30px;
    }

    .yellowText {
        font-size: 40px;
        line-height: 40px;

    }
}

@media(max-width:600px) {
    .tip-container .idea {
        top: -40px;
    }

    .tip-container .idea img {
        max-width: 130px;
        height: auto;
    }

    .tip-content {
        left: 90px;
        top: 85px;
    }

    .whiteText {
        font-size: 25px;
        line-height: 25px;
    }

    .yellowText {
        font-size: 30px;
        line-height: 35px;

    }
}

@media(max-width:550px) {
    .tip-content {
        top: 80px;
    }

    .whiteText {
        font-size: 20px;
        line-height: 20px !important;
    }

    .yellowText {
        font-size: 25px;
        line-height: 25px;
    }
}

@media(max-width:500px) {
    .tip-container .idea img {
        max-width: 110px;
        height: auto;
    }

    .tip-content {
        left: 75px;
        top: 60px;
    }

    .whiteText {
        font-size: 18px;
        line-height: 20px;
    }

    .yellowText {
        font-size: 25px;
        line-height: 25px;

    }
}

@media(max-width:450px) {

    .tip-content {
        left: 75px;
        top: 60px;
        line-height: 20px;
    }

    .whiteText {
        font-size: 16px;
        line-height: 20px;
    }

    .yellowText {
        font-size: 18px;
        line-height: 25px;

    }
}

@media(max-width:400px) {
    .tip-container .idea {
        top: -32px
    }

    .tip-container .idea img {
        max-width: 90px;
        height: auto;
    }

    .tip-content {
        left: 65px;
        top: 45px;
        line-height: 18px;
    }

}

@media(max-width:350px) {
    .tip-container .idea {
        top: -30px
    }

    .tip-container .idea img {
        max-width: 80px;
        height: auto;
    }

    .tip-content {
        left: 55px;
        top: 45px;
        line-height: 10px;
    }

    .whiteText {
        font-size: 12px;
    }

    .yellowText {
        font-size: 14px;
        line-height: 14px;

    }
}