.dark-side .image {
  width: 100%;
}

.dark-side .image img {
  width: 100%;
}

.dark-side .content {
  /* width: 100%; */
  /* padding-left: 150px; */
}

.dark-side .text1,
.dark-side .text2 {
  font-family: var(--font_banger) !important;
  font-size: 42px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 40px !important;
  color: var(--mahroon);
}

/************ court*****************/

.dark-side .text2 {
  color: var(--yellow);
}

.court .image img {
  max-width: 500px;
}

.court .content {
  padding-left: 50px;
}

.court .content img {
  width: 90%;
  max-height: 400px;
}

/*******************************example-exerise******************/

.example-exerise .question-container {
  flex-grow: 1;
}

.example-exerise .main-container .sub-container {
  width: 100% !important;
  max-width: unset !important;
}

.challenge-example-reverse .text-box {}

/************************media  Queries*********************/

@media (max-width: 1400px) {
  /* .dark-side .content {
    padding-left: 75px;
  } */
}

@media (max-width: 1200px) {
  .dark-side .content {
    /* width: 100%; */
    padding-left: 0px;
    margin-top: 32px;
  }

  .court .image {
    text-align: center;
  }
}

@media (max-width: 992px) {
  .court .status .col-lg-6 {
    text-align: center;
  }

  .thought .thought-sub-container {
    width: 100% !important;
  }
}