/* div#panel1a-header {
    background-color: var(--light-pink-background) !important;
} */

.accordion-heading {
    background-color: var(--light-pink-background) !important;
}

.accordion-heading svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    fill: var(--purple);
}

div#panel1a-content p {
    /* color: unset !important; */
    color: #323232;
    font-size: 24px !important;
    line-height: 30px !important;
    font-family: var(--font_comicRegular);
    font-weight: 700;
    margin-top: 8px;
}

div#panel1a-content p.component-sub-heading-style {
    color: #000;
    font-family: var(--font_comicRegular);
    font-size: 30px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px !important;
}

div#panel1a-content p.normal-text-black {
    color: #000;
    font-size: 24px !important;
    line-height: 30px !important;
    font-family: var(--font_comicRegular);
    font-weight: 700;
}

div#panel1a-content p.component-heading-style {
    color: var(--mahroon);
    font-size: 35px !important;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 40px !important;
}

div#panel1a-content p.listing {
    color: #323232;
    font-family: var(--font_comicRegular);
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    word-break: break-word;
}

div#panel1a-content p.text1 {
    font-family: var(--font_banger) !important;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 40px !important;
    color: var(--mahroon);
}

div#panel1a-content .left-content.checkbx-sty {
    margin-left: 4.5%;
    font-family: var(--font_comicRegular);
    font-size: 20px;
    color: #323232
}



/* media Queries*/


@media(max-width:992px) {

    div#panel1a-content p.component-sub-heading-style {
        font-size: 27px !important;
    }

    div#panel1a-content p.component-heading-style {
        font-size: 32px !important;
    }

}

@media(max-width:567px) {

    div#panel1a-content p.component-sub-heading-style {
        font-size: 25px !important;
    }

    div#panel1a-content p.component-heading-style {
        font-size: 27px !important;
    }

}