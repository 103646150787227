 .part4-first-Page .main-container {
     flex-wrap: nowrap !important;
     gap: 0pcx;
 }

 .main-container .part-4-container {
     width: 33%;
     max-width: 630px;
     margin-left: 0px;
 }

 .task-main-container .task-container .image-container,
 .main-container .part-4-container .image-container {
     justify-content: center;
     align-items: center;
     width: 80%;
     border-radius: 10px;
     border: 1px solid #DFDFDF;
     background: #FFF;
     box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
     padding: 20px;
     text-align: center;
     width: 100%;
 }

 .main-container .part-4-container .image-container img {
     width: 100%;
 }


 /****************************task images***************/

 .task-main-container {
     width: 100%;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     /* justify-content: center; */
     justify-content: space-between;

 }

 .task-main-container .task-container {
     width: 49%;
     /* max-width: 630px; */
 }

 .task-main-container .task-container .image-container {
     /* margin-bottom: 48px; */
     margin-bottom: 4%;
     width: 100%;
 }

 .task-main-container .task-container .image-container img {
     width: 100%;
 }

 .task-main-container .task-container .image-container:first-child {
     display: flex;
     width: 70%;
     margin: 4% auto;
     margin-top: 0px;
 }



 /****************************media queries ******************************/

 @media(max-width:992px) {
     .part4-first-Page .main-container {
         flex-wrap: wrap !important;
     }

     .main-container .part-4-container {
         min-width: 100%;
     }

     .main-container .part-4-container .image-container {
         width: 100%;
         margin-bottom: 50px;

     }

     .task-main-container {
         flex-wrap: wrap;
     }

     .task-main-container .task-container {
         width: 100%;
         max-width: unset;
     }

     .task-main-container .task-container .image-container {
         /* margin-bottom: 48px; */
         width: 100% !important;

     }

     .main-container .sub-container .image-container {
         margin-bottom: unset;
     }
 }