.letxRecap .sub-container {
    width: 48%;
    max-width: 700px;
    margin-bottom: 20px;
}

@media(max-width:992px) {
    .letxRecap .sub-container {
        width: 100%;
    }
}