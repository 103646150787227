 .card-body .main-badge-container {
     width: 100%;
     padding: 44px 17px 44px 17px;
 }

 .card-body .main-badge-container .badge-container {
     display: flex;
 }

 .card-body .main-badge-container .badge-container .current-badge {
     width: 90%;
 }

 .card-body .main-badge-container .badge-container .current-badge img {
     width: 100%;
     box-sizing: border-box;
     display: flex;
     justify-content: center;
     align-items: center;
     max-width: 337px !important;
     /* height: 302px; */
     max-height: 337px;
     border-radius: 30px;
     border: 2px solid #3F0866;
     background: #FFF;
     box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.25);
     padding: 18px 17px 17px 18px;
 }

 .card-body .main-badge-container .badge-container .all-badges {
     width: 85%;
     max-width: 850px;
 }

 .card-body .main-badge-container .badge-container .all-badges .bages {
     display: flex;
     flex-wrap: wrap;
     gap: 25px
 }

 .card-body .main-badge-container .badge-container .all-badges .bages img {
     width: 156px;
     height: 156px;
 }

 .badge-container .points-container p {
     font-family: var(--font_banger);
     -webkit-text-stroke: 1px black;
     color: var(--yellow);
     font-size: 85px !important;
     font-style: normal;
     font-weight: 400;
     line-height: 70px !important;
     margin: 40px 0px 0px 15px;
     word-break: normal;
 }

 .badge-info-container {
     width: 100%;
     margin-top: 137px;
 }

 .points-info p:nth-child(2),
 .badge-info-container .description {
     color: var(--medium_black);
     font-family: var(--font_comicRegular);
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: 24px;
 }

 .badge-info-container .info-container {
     display: flex;
     width: 100%;
     flex-wrap: wrap;
     gap: 5px;
 }

 .info-container .points-info {
     width: 33%;
     display: flex;
     /* max-width: 400px; */
     padding: 59px 21px;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     /* gap: 25px; */
     border-radius: 10px;
     border: 1px solid #DFDFDF;
     background: var(--light-pink-background);
     box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
     height: 400px;
 }

 .points-info p:nth-child(1) {
     font-family: var(--font_banger);
     -webkit-text-stroke: 1px black;
     color: var(--yellow);
     font-size: 40px !important;
     font-style: normal;
     font-weight: 400;
     line-height: 40px !important;
     word-break: normal;
     text-align: center;
 }


 .points-info p:nth-child(2) {
     text-align: center;
 }

 .image-style-blur {
     border: 2px solid #3F0866;
     opacity: 0.5;
 }

 .sidebar-badge-style-blur {
     opacity: 0.5;
 }




 /********* media Quesries ***********/

 @media(max-width:1491px) {
     .card-body .main-badge-container .badge-container .all-badges {
         display: flex;
         flex-direction: column;
         align-items: center;
     }

     .card-body .main-badge-container .badge-container .all-badges .bages {
         justify-content: center;
     }

     .badge-info-container .info-container {
         gap: 15px;

     }

     .info-container .points-info {
         width: 49%;
     }
 }

 @media(max-width:1199px) {
     .card-body .main-badge-container .badge-container .all-badges {
         margin-top: 137px;
         align-items: start;
     }

     .card-body .main-badge-container .badge-container .all-badges .bages {
         justify-content: start;
     }

 }

 @media(max-width:1105px) {

     .card-body .main-badge-container .badge-container div:nth-child(1),
     .card-body .main-badge-container .badge-container div:nth-child(2) {
         width: 100% !important;
     }





 }

 @media(max-width:1051px) {

     .card-body .main-badge-container .badge-container div:nth-child(1),
     .card-body .main-badge-container .badge-container div:nth-child(2) {
         width: 85% !important;
         margin: auto;
     }

     /* .card-body .main-badge-container .badge-container .all-badges {
         width: 85% !important;
         margin: auto;
         margin-top: 137px;
     } */

     .card-body .main-badge-container .badge-container .current-badge img {
         margin: auto;
     }

     .card-body .main-badge-container .badge-container .all-badges {
         align-items: center;
         margin-top: 137px !important;
     }

     .card-body .main-badge-container .badge-container .all-badges .bages {
         width: 85% !important;
         margin: 0px auto;
     }

     .card-body .main-badge-container .badge-container .current-badge {
         text-align: center;
     }

     .badge-container .points-container {
         display: flex;
         justify-content: center;
         /* margin-top: 40px; */
     }

     .badge-container .points-container p {
         margin: 40px 0px 0px 0px;
     }

     .badge-info-container .info-container {
         justify-content: center;
     }

     .badge-info-container {
         text-align: center;
     }

     .info-container .points-info {
         width: 100%;
     }


 }

 @media(max-width:500px) {
     .badge-container .points-container p {
         font-size: 72px !important;

     }
 }