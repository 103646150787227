.main-container .part-5-container {
    width: 32%;
    max-width: 630px;
    margin-left: 0px;
}

.main-container .part-5-container .image-container {
    justify-content: center;
    align-items: center;
    width: 80%;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
    padding: 20px;
    text-align: center;
    width: 100%;
}

.main-container .part-5-container .image-container img {
    width: 100%;
}


@media(max-width:1100px) {
    .main-container .part-5-container {
        min-width: 100%;
    }

    .main-container .part-5-container .image-container {
        width: 100%;
        margin-bottom: 10px;

    }
}