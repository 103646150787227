.main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  justify-content: space-between;
}

.main-container .container {
  flex-shrink: 0;
  width: 48.7%;
  max-width: 630px;
  margin-left: 0px;
  padding: 0px;
  /* margin-bottom: 50px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  /* margin: auto; */
  margin-top: 0px !important;
  margin-bottom: 15px;
}

.main-container .container .image-container {
  display: flex;
  flex-direction: column;
  max-width: 630px;
  /* max-height: 825px !important; */
  /* height: 90%; */
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  margin-top: 0px !important;
  margin-bottom: 10px;
  /* display: unset; */
}

.main-container .container .image-container img {
  width: 100%;
}

/******************* media queries ***************/

@media (max-width: 992px) {
  .main-container {
    gap: 0px;
  }

  .main-container .container {
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: unset;
  }

  .main-container .container .image-container {
    width: 100%;
  }
}

/* @media (max-width: 992px) {
  .main-container .container {
  }
} */