/********************************bens model 2 graph***************************/
.bens-model-2 .main-container .sub-container {
    width: 65%;
    max-width: unset !important;
}

@media(max-width:992px) {

    .bens-model-2 .main-container {
        margin: 0px !important;
    }

    .bens-model-2 .main-container .sub-container {
        width: 100%;

    }
}