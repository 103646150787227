.part7-main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.25%;
    /* justify-content: space-between; */
    /* justify-content: center; */
}

.part7-main-container .container {
    display: flex;
    /* max-width: 362px;
    max-height: 362px; */
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
    text-align: center;
    padding: 20px;
    /* width: 24%; */
    width: 32.5%;
    margin: 0px;
    margin-bottom: 18px;
}

.part7-main-container .container img {
    width: 100%;
    /* height: 200px; */
}

/*********************** media quesries*************/

/* @media(max-width:1733px) {
    .part7-main-container .container {
        width: 32%;
    }
} */

@media(max-width:1400px) {

    .part7-main-container {
        gap: 2%;
    }

    .part7-main-container .container {
        width: 49%;

    }
}

@media(max-width:992px) {
    .part7-main-container .container {
        min-width: 100%;
    }
}