.exercise-tip-container {
    width: 100%;
    margin: 80px 0px;
    position: relative;
    display: flex;
}

.exercise-tip-container .exercise-answer {
    width: 70%;
    margin-right: 20px;
}

.exercise-tip-container .why-image {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.exercise-tip-container .why-image img {
    width: 90%;
    margin: auto;
}

.exercise-tip-container .exercise-answer img {
    width: -webkit-fill-available !important;
    height: auto;
    object-fit: contain;
    max-width: 923px;
    width: 100%;
}

/***** idea image *******/

.exercise-tip-container .idea {
    position: absolute;
    top: -50px;
}

.exercise-tip-container .idea img {
    max-width: 219px;
    height: auto;
}

/****** Content******/

.exercise-tip-container .tip-content {
    position: absolute;
    top: 165px;
    left: 145px;
    max-width: 600px;
}

.exercise-tip-container .whiteText {
    color: #FFF;
    font-family: var(--font_banger);
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
}

.exercise-tip-container .yellowText {
    color: #FECB00;
    font-family: var(--font_banger);
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
}


@media(max-width:1650px) {
    .exercise-tip-content .whiteText {
        font-size: 45px;
    }

    .exercise-tip-container .yellowText {
        font-size: 60px;
    }
}

@media(max-width:1450px) {
    .exercise-tip-container .idea img {
        max-width: 170px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 105px;
        top: 110px;
        max-width: 500px !important;
    }

    .exercise-tip-container .whiteText {
        font-size: 40px;
        line-height: 60px;
    }

    .exercise-tip-container .yellowText {
        font-size: 50px;
        line-height: 60px;

    }
}

@media(max-width:1200px) {
    .exercise-tip-container .idea img {
        max-width: 150px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 95px;
        top: 100px;
        max-width: 400px !important;
        /* max-width: 72% !important; */
    }

    .exercise-tip-container .whiteText {
        font-size: 30px;
        line-height: 35px;
    }

    .exercise-tip-container .yellowText {
        font-size: 40px;
        line-height: 40px;

    }
}

@media(max-width:992px) {
    .exercise-tip-container .tip-content {
        /* max-width: 400px !important; */
        max-width: 72% !important;
    }

    .exercise-tip-container {
        width: 100%;
        margin: 80px 0px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }

    .exercise-tip-container .exercise-answer {
        width: 100%;
        margin-bottom: 20px;
    }

    .exercise-tip-container .why-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
}

@media(max-width:820px) {
    .exercise-tip-container .tip-content {
        left: 95px;
        top: 110px;
        /* max-width: 400px !important; */
        max-width: 72% !important;
    }

    .exercise-tip-container {
        width: 100%;
        margin: 80px 0px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }

    .exercise-tip-container .exercise-answer {
        width: 100%;
        margin-bottom: 20px;
    }

    .exercise-tip-container .why-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .exercise-tip-container .whiteText {
        font-size: 50px;
        line-height: 50px;
    }

    .exercise-tip-container .yellowText {
        font-size: 55px;
        line-height: 55px;

    }
}

@media(max-width:767px) {
    .exercise-tip-container .idea img {
        max-width: 160px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 105px;
        top: 110px;
        max-width: 480px !important;
    }

    .exercise-tip-container .whiteText {
        font-size: 40px;
        line-height: 45px;
    }

    .exercise-tip-container .yellowText {
        font-size: 55px;
        line-height: 65px;
    }
}

@media(max-width:650px) {
    .exercise-tip-container .idea img {
        max-width: 150px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 90px;
        top: 95px;
        max-width: 400px !important;
    }

    .exercise-tip-container .whiteText {
        font-size: 35px;
        line-height: 35px;
    }

    .exercise-tip-container .yellowText {
        font-size: 45px;
        line-height: 55px;
    }
}

@media(max-width:600px) {
    .exercise-tip-container.idea img {
        max-width: 130px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 90px;
        top: 100px;
        /* max-width: 350px !important; */
        width: 65%;
    }

    .exercise-tip-container .whiteText {
        font-size: 25px;
        line-height: 25px;
    }

    .exercise-tip-container .yellowText {
        font-size: 35px;
        line-height: 35px;
    }
}

@media(max-width:500px) {
    .exercise-tip-container .idea img {
        max-width: 120px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 80px;
        top: 75px;
        width: 65%;
    }

    .exercise-tip-container .whiteText {
        font-size: 22px;
        line-height: 20px;
    }

    .exercise-tip-container .yellowText {
        font-size: 25px;
        line-height: 25px;

    }
}

@media(max-width:450px) {
    .exercise-tip-container .idea img {
        max-width: 110px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 80px;
        top: 65px;
        width: 65%;
    }
}

@media(max-width:400px) {
    .exercise-tip-container .idea img {
        max-width: 100px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 60px;
        top: 60px;
        width: 65%;
    }

    .exercise-tip-container .whiteText {
        font-size: 18px;
        line-height: 18px;
    }

    .exercise-tip-container .yellowText {
        font-size: 21px;
        line-height: 21px;

    }
}

@media(max-width:350px) {
    .exercise-tip-container .idea {
        position: absolute;
        top: -36px;
    }

    .exercise-tip-container .idea img {
        max-width: 80px;
        height: auto;
    }

    .exercise-tip-container .tip-content {
        left: 55px;
        top: 45px;
        width: 68%;
    }

    .exercise-tip-container .whiteText {
        font-size: 16px;
        line-height: 18px;
    }

    .exercise-tip-container .yellowText {
        font-size: 18px;
        line-height: 20px;

    }
}