.main-container.control-situation .container {
    max-height: 660px;
    justify-content: center;
    max-width: unset;
}

.main-container.control-situation .container .image-container {
    height: 100%;
    margin-bottom: 0px;
    max-width: 660px;
}

.main-container.control-situation .container .image-container img {
    height: 100%;
}