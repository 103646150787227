.sushan-thought-container {
    background: var(--light-pink-background);
    border-radius: 10px;
    background: #f2ecfa;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.sushan-problem-outer,
.reason-box-outer,
.answer-box-outer {
    display: none;
}

@media(max-width:1500px) {
    .sushan-caual-thought .answer-box {
        display: none;
    }

    .answer-box-outer {
        display: block;
        padding: 0px 40px 40px 40px;
    }
}

@media(max-width:1400px) {
    .sushan-caual-thought .reason-box {
        display: none;
    }

    .reason-box-outer {
        display: block;
        padding: 0px 40px 0px 40px;
    }
}

@media(max-width:1200px) {
    .sushan-problem {
        display: none;
    }

    .sushan-problem-outer {
        display: block;
        padding: 0px 40px 0px 40px;
    }

    .sushan-thought-container .example-container {
        margin: auto;
        padding-bottom: 0px;
    }

    .sushan-caual-thought {
        margin-top: 20px !important;
    }
}

@media(max-width:992px) {

    .answer-box-outer,
    .reason-box-outer,
    .sushan-problem-outer {
        padding: 0px 20px 0px 20px;
    }

    .answer-box-outer {
        padding: 0px 20px 20px 20px;
    }
}