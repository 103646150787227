main-comicContainer {
  /* position: relative; */
}

.main-comicResouces {
  margin-top: 50px;
}

.comic-container {
  width: 100%;
  /* border: 1px solid red; */
  max-width: 1532px;
  margin: auto;
  justify-content: space-between;
  /* gap: 10px; */
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.comic-container > div {
  width: 49.5%;
  display: flex;
  max-width: 731px;
  /* height: 296px; */
  border-radius: 20px;
  box-shadow: 0px -5px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 25px;
  background-color: var(--yellow);
}

.comic-container .image-contaier {
  width: 246px;
  height: 246px;
  margin-right: 21px;
  border-radius: 13px;
  /* border: 1px solid red; */
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comic-container .image-contaier .imageStyle {
  /* width: 246px;
    height: 246px; */
  width: 80%;
  height: 90%;
  margin: auto;
  border-radius: 13px;
}

.comic-container .info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}

.comic-container .info-container .title,
.comic-container .info-container .text {
  color: var(--purple);
  font-family: var(--font_banger);
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.info-container h1 {
  margin: 0px !important;
}

.comic-container .info-container .text {
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font_comicRegular);
  margin-top: 20px;
  /* margin-bottom: 0px !important; */
  margin-bottom: 5px !important;
}

.info-container .button {
  background: var(--purple);
  border-radius: 10px;
  font-family: var(--font_comicRegular);
  letter-spacing: 0px;
  color: var(--yellow);
  font-size: 17px;
  max-width: 328px;
  /* margin-right: auto;
    margin-top: auto; */
  font-weight: 700;
  line-height: 30px;
  padding: 30px 30px;
  display: flex;
  align-items: center;
  margin: auto;
}

.info-container .button:hover {
  opacity: 0.9;
}

.info-container .button img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.info-container .button span {
  color: var(--yellow);
  text-align: center;
  font-family: var(--font_comicRegular);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.comic-container .pdf-container {
  background-color: var(--purple);
}

.comic-container .info-container .pdf-title,
.comic-container .info-container .pdf-text {
  color: var(--white);
}

.info-container .pdf-button {
  background: var(--yellow);
}

.info-container .button .pdf-but-text {
  color: var(--purple);
}

.marginBotoom {
  padding: 44px !important;
  padding-top: 0px !important;
}

.main-comicContainer {
  /* margin-top: 70px; */
}

.main-comicContainer .comic-video {
  max-width: 700px;

  position: absolute;
  left: 0px;
  /* bottom: 44px; */
  /* border: 1px solid red; */
  /* display: none; */
  width: 49%;
  /* height: 100%; */
  max-height: 296px;
  display: none;
  transition: left 0.3 ease-in-out;
}

.show {
  /* left: 300px !important; */
  display: block !important;
}

/* Antriksh-dev */

.modalVideo {
  max-width: 55% !important;
  /* height: 600px; */
}

.video-model-content {
  height: 600px;
}

/* .modal-content .modal-bodye video {
    height: 700px ;
} */

.modalVideo .modal-content .modal-header .title {
  color: var(--purple);
  font-family: var(--font_banger);
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  width: 95%;
  margin: auto;
  padding-left: 40px;
}

.greetingMessage1 {
  color: #6a3851;
  font-size: 32px;
  margin: 0px !important;
  font-weight: 400;
  line-height: 60.5px;
  font-style: normal;
  font-family: var(--font_banger);
}

.greetingMessage2 {
  color: #fecb00;
  font-size: 43px;
  margin: 0px !important;
  font-weight: 400;
  line-height: 60.5px;
  font-style: normal;
  font-family: var(--font_banger);
}

.greetingMessage3 {
  color: #fecb00;
  font-size: 32px;
  margin: 0px !important;
  font-weight: 400;
  line-height: 60.5px;
  font-style: normal;
  font-family: var(--font_banger);
}

/* media query */

@media (max-width: 1386px) {
  .marginBotoom {
    padding: 16px !important;
  }
}

@media (max-width: 1329px) {
  .comic-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .comic-container > div {
    width: 100%;
    max-width: 100%;
  }

  .comic-container .pdf-container {
    /* width: 100%; */
    margin-top: 30px;
  }

  .main-comicContainer .comic-video {
    width: 100%;
  }
}

@media (max-width: 825px) {
  .comic-container > div {
    flex-direction: column;
    height: auto;
  }

  .comic-container .image-contaier {
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 20px;
  }

  .comic-container .image-contaier .imageStyle {
    width: 80%;
    height: 90%;
    /* width: 100% !important; */
    /* height: auto; */
  }

  .info-container .button {
    margin-top: 20px;
  }

  .comic-container .info-container {
    width: 100%;
  }

  .info-container .button {
    max-width: unset;
    justify-content: center;
  }
}

@media (max-width: 450px) {
  .greetingMessage2 {
    font-size: 32px !important;
  }
}
