.part1-main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: max-content;
}

.part1-main-container .image-with-text {
    display: flex;
    flex-direction: column;
    width: 24%;
    align-items: center;
}

.part1-main-container .image-with-text.thought-review {
    /* align-items: unset; */
    /* flex-direction: unset; */
    /* display: grid; */
    /* grid-template-rows: 300px auto auto; */
}

.part1-main-container .thought-review .thought-review-response {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100px;
    width: 100%;
    scrollbar-width: 2px;
    scrollbar-color: #6a3851 #ccc;
    /* appearance: none; */
    margin-bottom: 20px;
    background-color: #e9ecef87 !important;
    padding: 5px;
    border-radius: 5px;
}

.part1-main-container .example-box {
    width: 100%;
}

/* Works on Chrome, Edge, and Safari */

.part1-main-container .thought-review .thought-review-response::-webkit-scrollbar {
    width: 2px;
    /* appearance: none; */
}

.part1-main-container .thought-review .thought-review-response::-webkit-scrollbar-track {
    /* background: #d9d9d9 !important; */
}

.part1-main-container .thought-review .thought-review-response::-webkit-scrollbar-thumb {
    background-color: #ccc !important;
    border-radius: 5px;
}

.part1-main-container .image-with-text .review-content {
    width: 100%;
}

.part1-main-container .image-with-text .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
    text-align: center;
    padding: 20px;
    width: 100%;
    margin: 0px;
    margin-bottom: 35px;
}

.part1-main-container .container img {
    width: 100%;
    height: 370px;
    object-fit: contain
}

.part1-main-container .container img.causes {
    width: 100%;
    max-height: 321px;
    object-fit: contain
}

.brain-plastic-exercise .main-container .container p {
    flex-grow: 1;
    text-align: center;
}


/*********************** media quesries*************/

@media(min-width:1400px) and (max-width:1600px) {
    .part1-main-container .container img {
        height: 300px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .part1-main-container .container img {
        height: 250px;
    }
}

@media(max-width:1200px) {
    .part1-main-container .image-with-text {
        width: 49%;
    }

    .part1-main-container .container {
        width: 100%;

    }

    .part1-main-container .image-with-text p {
        margin-bottom: 35px;
    }
}

@media(max-width:992px) {
    .part1-main-container .image-with-text {
        width: 100%;
    }

    .part1-main-container .container {
        min-width: 100%;
    }

    .part1-main-container .container img,
    .part1-main-container .container img.causes {
        width: 100%;
        max-height: unset;
        height: unset;
        object-fit: contain
    }

    .brain-plastic-exercise .main-container .container {
        margin-bottom: 20px;
    }

    .part1-main-container .thought-review .thought-review-response {
        overflow-y: unset;
        overflow-x: unset;
        height: unset;
        width: 100%;
        scrollbar-width: none;
        scrollbar-color: unset;
        margin-bottom: unset;
        background-color: unset !important;
        padding: 5px;
        border-radius: 5px;
    }
}


/* /******* for plastic brain exerciser******** */

.main-container .container .plastic-brain {
    min-height: 630px;
}

@media(min-width:1200px) and (max-width:1400px) {
    .main-container .container .plastic-brain {
        min-height: 500px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .main-container .container .plastic-brain {
        min-height: 400px;
    }
}

@media(max-width:778px) {
    .main-container .container .plastic-brain {
        min-height: 400px;
    }
}

@media(max-width:500px) {
    .main-container .container .plastic-brain {
        min-height: unset;
    }
}



@media print {
    .part1-main-container {
        flex-wrap: wrap !important;
    }

    .part1-main-container {
        flex-wrap: wrap !important;
        width: 100% !important;
    }

    .part1-main-container .image-with-text {
        width: 25% !important;
    }

    .part1-main-container .image-with-text .container {
        width: 98% !important;
        min-width: unset !important;
        max-width: unset !important;
    }

    .part1-main-container .image-with-text .container img {
        width: 100% !important;
        height: unset !important;
        object-fit: contain
    }

    .brain-plastic-exercise .main-container {
        display: flex;
        flex-direction: row !important;
    }

    .brain-plastic-exercise .main-container .container {
        width: 49% !important;
        min-width: unset !important;
    }

    .brain-plastic-exercise .main-container .container>p {
        text-wrap: wrap !important;
        word-break: break-all !important;
    }

    .brain-plastic-exercise .main-container .container .image-container.plastic-brain {
        width: 300px !important;
        min-height: unset !important;
        height: 300px;
    }

    .brain-plastic-exercise .main-container .container .image-container.plastic-brain img {
        width: 200px !important;
        height: 200px !important;
    }

    .example-container {
        display: flex !important;
        flex-direction: column !important;
    }

    .example-container .image-box {
        margin-bottom: 50px !important;
    }
}