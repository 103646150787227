/*************************check list******************************/

.checklist {
    justify-content: flex-start;
    gap: 1.3%;
}

.checklist .image-with-text {
    margin-bottom: 1.3%;
}

.checklist .image-with-text .container {
    margin-bottom: 0px;
}


/****************************For thinking-errors ********************/

.thinking-errors {
    justify-content: flex-start;
    gap: 1.3%
}



/****************************self - defence*******************/

.self-defence {
    justify-content: flex-start;
    gap: 1.3%;
}

.self-defence .image-with-text {
    width: 32.46%;
}

@media(max-width:1420px) {

    .checklist .image-with-text,
    .thinking-errors .image-with-text {
        width: 32%;
        margin-bottom: 2%;
    }

    .thinking-errors,
    .checklist {
        gap: 2%;
    }

    /* .thinking-errors {
        gap: 1.3%;
    } */

    .self-defence .image-with-text {
        width: 32.46%;
    }

    .self-defence {
        gap: 1.3%;
    }
}

@media(min-width:992px) and (max-width:1200px) {

    .self-defence .image-with-text,
    .checklist .image-with-text,
    .thinking-errors .image-with-text {
        width: 49%;
    }

    .self-defence,
    .checklist {
        gap: 2%;
    }

}

@media(max-width:992px) {

    .checklist .image-with-text,
    .thinking-errors .image-with-text {
        width: 100%;
        margin-bottom: 16px;
    }
}