.avoidance_Safety .myRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 20px;
    background: var(--light-pink-background);
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
    margin: 50px 0px;
    padding: 40px;
    display: flex;
}

.myRow .myColumn1 {
    width: 30%;
}

.imageContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
    padding: 20px;
    text-align: center;
}

.imageContainer img {
    width: 100%;
}

.myRow .myColumn2 {
    width: 70%;
    margin-left: 40px;
}

.myRow .myColumn2 .myRow_1 {
    display: flex;
    flex-direction: row;
}

.myRow .myColumn2 .myRow_1 .myColumn1 {
    width: 70%;
}

.myRow .myColumn2 .myRow_1 .myColumn2 {
    width: 30%;
}


.avoidance_Safety .main-container .container p {
    flex-grow: 1;
}

.main-container.problem-avoidance .container {
    height: unset;
}

.main-container.problem-avoidance .container .image-container {
    height: 100%;
}


/**** media Queries ********/


@media(max-width:1680px) {
    .avoidance_Safety .myRow {
        flex-direction: column;
    }

    .myRow .myColumn2,
    .myRow .myColumn1 {
        width: 100%;
    }

    .myRow .myColumn1 .imageContainer {
        max-width: 550px;
        margin: auto;
    }

    .myRow .myColumn2 {
        margin-top: 40px;
        margin-left: 0px;
    }

    .myRow .myColumn2 .head {
        text-align: center;
    }


    .myRow .myColumn2 .myRow_1 .myColumn1 {
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .myRow .myColumn2 .myRow_1 .myColumn2 {
        /* margin */
        margin-top: 0px;
    }
}


@media(max-width:1320px) {
    .myRow .myColumn2 .myRow_1 {
        /* display: ; */
        flex-direction: column;
    }

    .myRow .myColumn2 .myRow_1 .myColumn2,
    .myRow .myColumn2 .myRow_1 .myColumn1 {
        margin-right: 0px;
        width: 100%;
    }

    .myRow .myColumn2 .myRow_1 .myColumn1 {
        margin-bottom: 40px;
    }

    .myRow .myColumn2 .myRow_1 .myColumn2 {
        max-width: 550px;
        margin: auto;
    }
}

@media(max-width:992px) {

    .myRow .myColumn1 .imageContainer,
    .myRow .myColumn2 .myRow_1 .myColumn2 {
        max-width: unset;
    }
}

@media(max-width:768px) {
    .avoidance_Safety .myRow {
        padding: 20px;
    }
}