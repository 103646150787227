.clinician-image {
    border-radius: 50%;
    border: 1px solid #C3C3C3;
    object-fit: contain;
    height: 92px;
    width: 92px;
    text-align: center
}

.clinician-image img {
    /* height: 100%; */
    height: 90px;
    width: 90px;
    border-radius: 50%;
}

.audio-progress-bar {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;

}


/* =========================
Custom Input range 
=============================*/

/* Input range - chrome and safari */

input[type="range"] {
    --range-progress: 0;

    -webkit-appearance: none;
    position: relative;
    background: #ccc;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    cursor: pointer;
}

/* Input range - firefox */
input[type="range"]::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    cursor: pointer;
}

/* played progress length - Chrome & safari*/
input[type="range"]::before {
    content: '';
    height: 4px;
    background: var(--mahroon);
    width: var(--range-progress);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
}

/* played progress length - firefox */
input[type="range"]::-moz-range-progress {
    background: var(--mahroon);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 4px;
}

/* slider thumb - chrome and safari */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: none;
    background-image: linear-gradient(180deg, #E8E8E8 0%, #929292 100%);
    filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.16));
    cursor: pointer;
    position: relative;
}

/* dragging thumb - chrome and safari */
input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
    border: transparent;
}

/* slider thumb - firefox */
input[type="range"]::-moz-range-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    /* background: #f50; */
    background-image: linear-gradient(180deg, #E8E8E8 0%, #929292 100%);
    filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.16));
    cursor: pointer;
    border: transparent;
    position: relative;
}

/* dragging thumb - firefox */
input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
}

input[type='range']:focus {
    border: none !important;
}

.controls-wrapper {
    text-align: center;
    display: flex;
}


.volume {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    justify-items: center;
    align-items: center;
}

.volume:hover {
    cursor: pointer;
}

/*media query */


@media(max-width:1400px) {
    .clinician-image {
        height: 62px;
        width: 62px;
    }

    .clinician-image img {
        height: 62px;
        width: 62px;
    }
}

@media(max-width:1200px) {
    /* .footer .col-md-5 {
    width: 100%;
  } */

    .controls-wrapper .volume input[type='range'] {
        display: none;
    }
}

@media(max-width:992px) {
    .audio-progress-bar {
        display: none;
    }

    .clinician-image {
        align-self: flex-end;
    }

    .clinician-image img {
        height: 60px;
        width: 55px;
    }
}

@media(max-width:768px) {
    .clinician-image {
        height: 40px;
        width: 40px;
    }

    .clinician-image img {
        height: 35px;
        width: 35px;
    }

    .footer-play-button {
        height: 40px;
        width: 40px;
    }

    .footer-play-button img {
        height: 39px;
        width: 38px;
    }

    .controls-wrapper .volume input[type='range'] {
        display: block;
    }
}

@media(max-width:500px) {
    .controls-wrapper .volume input[type='range'] {
        display: none;
    }
}