.end-module-2 .main-container .container {
    /* height: 66;
     */
    height: 630px;
}

.end-module-2 .main-container .container .image-container {
    object-fit: contain;
    height: 630px;
}

.end-module-2 .main-container .container .image-container img {
    /* width: 100%; */
    height: 100%;
    /* height: 630px; */
}

@media(max-width:1700px) {
    .end-module-2 .main-container .container {
        height: 630px;
    }
}

@media(max-width:1700px)and (min-width:1500px) {
    .end-module-2 .main-container .container .image-container img {
        width: 100%;
        /* height: 100%; */
        height: 530px;
    }

    .end-module-2 .main-container .container {
        /* height: 66;
         */
        height: unset;
    }

    .end-module-2 .main-container .container .image-container {
        object-fit: contain;
        height: unset;
    }
}

@media(max-width:1500px)and (min-width:1200px) {
    .end-module-2 .main-container .container .image-container img {
        width: 100%;
        /* height: 100%; */
        height: 430px;
    }

    .end-module-2 .main-container .container {
        /* height: 66;
         */
        height: unset;
        /* max-width: 430px; */
    }

    .end-module-2 .main-container .container .image-container {
        object-fit: contain;
        height: unset;
        /* max-width: 430px; */
    }
}

@media(max-width:1200px)and (min-width:992px) {
    .end-module-2 .main-container .container .image-container img {
        width: 100%;
        /* height: 100%; */
        height: 330px;
    }

    .end-module-2 .main-container .container {
        /* height: 66;
         */
        height: unset;
        /* max-width: 430px; */
    }

    .end-module-2 .main-container .container .image-container {
        object-fit: contain;
        height: unset;
        /* max-width: 430px; */
    }
}

@media(max-width:992px) {
    .end-module-2 .main-container .container .image-container img {
        width: 100%;
        height: 100%;
        max-height: 474px;
    }

    .end-module-2 .main-container .container {

        height: auto;

    }

    .end-module-2 .main-container .container .image-container {
        object-fit: contain;
        height: auto;
        /* max-width: 430px; */
    }
}