 .waiting-for-the-bus .tip-content .whiteText {
     color: #FFF;
     font-family: var(--font_banger);
     font-size: 35px !important;
     font-style: normal;
     font-weight: 400;
     line-height: 40px;
 }

 .waiting-for-the-bus .tip-content .yellowText {
     font-size: 40px !important;
     line-height: 45px;
 }


 @media(max-width:1100px) {
     .waiting-for-the-bus .tip-content {
         max-width: 500px !important;
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 30px !important;
         line-height: 35px;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 35px !important;
         line-height: 35px;

     }
 }

 @media(max-width:980px) {
     .waiting-for-the-bus .tip-content {
         max-width: 420px !important;
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 25px !important;
         line-height: 25px;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 30px !important;
         line-height: 35px;

     }
 }

 @media(max-width:900px) {
     .waiting-for-the-bus .tip-content {
         top: 90px;
         max-width: 350px !important;
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 20px !important;
         line-height: 20px;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 25px !important;
         line-height: 30px;

     }
 }

 @media(max-width:820px) {
     .tip-container.waiting-for-the-bus {
         margin: 40px 0px;
     }

     .waiting-for-the-bus .tip-content {
         top: 115px;
         max-width: 70% !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 30px !important;
         line-height: 30px;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 35px !important;
         line-height: 35px;

     }
 }

 @media(max-width:740px) {

     .waiting-for-the-bus .tip-content {
         top: 115px;
         max-width: 70% !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 25px !important;
         line-height: 25px;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 30px !important;
         line-height: 30px;

     }
 }

 @media(max-width:700px) {
     .tip-container.waiting-for-the-bus .idea {
         top: -40px;
     }

     .tip-container.waiting-for-the-bus .idea img {
         max-width: 140px;
     }

     .waiting-for-the-bus .tip-content {
         left: 95px;
         top: 90px;
         max-width: 70% !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 20px !important;
         line-height: 20px;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 25px !important;
         line-height: 25px;

     }
 }

 @media(max-width:600px) {
     .tip-container.waiting-for-the-bus {
         margin: 20px 0px 30px 0px;
     }

     .tip-container.waiting-for-the-bus .idea {
         top: -40px;
     }

     .tip-container.waiting-for-the-bus .idea img {
         max-width: 130px;
     }

     .waiting-for-the-bus .tip-content {
         left: 90px !important;
         top: 80px;
         max-width: 70% !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 20px !important;
         line-height: 20px;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 25px !important;
         line-height: 25px;

     }
 }

 @media(max-width:550px) {
     .tip-container.waiting-for-the-bus .idea {
         top: -30px;
     }

     .tip-container.waiting-for-the-bus .idea img {
         max-width: 110px;
     }

     .waiting-for-the-bus .tip-content {
         left: 78px !important;
         top: 74px;
         max-width: 70% !important;
         line-height: 20px !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 15px !important;
         line-height: 15px !important;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 20px !important;
         line-height: 20px;

     }
 }

 @media(max-width:500px) {
     .tip-container.waiting-for-the-bus .idea {
         top: -30px;
     }

     .tip-container.waiting-for-the-bus .idea img {
         max-width: 100px;
     }

     .waiting-for-the-bus .tip-content {
         left: 70px !important;
         top: 63px;
         max-width: 70% !important;
         line-height: 17px !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 15px !important;
         line-height: 15px !important;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 20px !important;
         line-height: 20px;

     }
 }

 @media(max-width:440px) {
     .tip-container.waiting-for-the-bus .idea {
         top: -28px;
     }

     .tip-container.waiting-for-the-bus .idea img {
         max-width: 90px;
     }

     .waiting-for-the-bus .tip-content {
         left: 62px !important;
         top: 59px;
         max-width: 70% !important;
         line-height: 15px !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 15px !important;
         line-height: 15px !important;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 18px !important;
         line-height: 18px;

     }
 }

 @media(max-width:400px) {
     .tip-container.waiting-for-the-bus .idea {
         top: -23px;
     }

     .tip-container.waiting-for-the-bus .idea img {
         max-width: 80px;
     }

     .waiting-for-the-bus .tip-content {
         left: 56px !important;
         top: 48px;
         max-width: 70% !important;
         line-height: 13px !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 12px !important;
         line-height: 12px !important;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 14px !important;
         line-height: 14px;

     }
 }

 @media(max-width:350px) {
     .tip-container.waiting-for-the-bus .idea {
         top: -25px;
     }

     .tip-container.waiting-for-the-bus .idea img {
         max-width: 75px;
     }

     .waiting-for-the-bus .tip-content {
         left: 50px !important;
         top: 44px;
         max-width: 70% !important;
         line-height: 12px !important;
         /* max-width: 350px !important; */
     }

     .waiting-for-the-bus .tip-content .whiteText {
         font-size: 10px !important;
         line-height: 6px !important;
     }

     .waiting-for-the-bus .tip-content .yellowText {
         font-size: 12px !important;
         line-height: 12px;

     }
 }