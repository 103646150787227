.safety-behaviours .safety-main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap; */
    gap: 10px;
    flex-wrap: wrap;
    /* border: 1px solid red; */
    justify-content: space-between;
    margin: 50px 0px;
}

.safety-behaviours .safety-main-container .container {
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
    text-align: center;
    padding: 20px;
    width: 19%;
    margin: 0px;
    display: unset;
    /* flex-direction: column;
    justify-content: space-between; */
}

.safety-behaviours .safety-main-container .container img {
    width: 100%;
}

.cause-harm .container {
    flex-shrink: 1;
    width: 40% !important;
    max-width: 530px !important;
    margin: 0px auto !important;
}

.cause-harm .cause-harm-container .container {
    justify-content: space-between;
}

.cause-harm .cause-harm-container .container .image-container {
    min-height: 675px;
    justify-content: start;
}

.cause-harm .cause-harm-container .container .image-container img {
    width: 80%;
}

/*********************media Query*************************/

@media(max-width:1600px) {
    .safety-behaviours .safety-main-container .container {
        width: 32.7%;
    }
}

@media(max-width:1550px) {
    .cause-harm .cause-harm-container .container .image-container {
        min-height: 629px;
    }
}

@media(max-width:1400px) {
    .safety-behaviours .safety-main-container .container {
        width: 49%;
    }
}

@media(max-width:1350px) {
    .cause-harm .cause-harm-container .container .image-container {
        min-height: 597px;
    }
}

@media(max-width:1100px) {
    .cause-harm .container {
        width: 48% !important;
    }
}

@media(max-width:1150px) {
    .cause-harm .cause-harm-container .container .image-container {
        min-height: 573px;
    }
}


/* @media(max-width:992px) {
    .safety-behaviours .safety-main-container .container {
        width: 100%;
    }
} */
@media(max-width:820px) {
    .safety-behaviours .safety-main-container .container {
        width: 100%;
        margin: auto;
    }

    .cause-harm .container {
        width: 100% !important;
    }
}